
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexxUat02VlFkMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/index.vue?macro=true";
import { default as indexCmJXtgaSUEMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/login/index.vue?macro=true";
import { default as indexaacAAG5ioGMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/magic-link/index.vue?macro=true";
import { default as not_45yetQVaSvFfSA5Meta } from "/codebuild/output/src1730893769/src/zazos-web/pages/not-yet.vue?macro=true";
import { default as indexkWDBVDFdA2Meta } from "/codebuild/output/src1730893769/src/zazos-web/pages/reset-password/index.vue?macro=true";
import { default as set_45workspacezALjN39qeAMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/set-workspace.vue?macro=true";
import { default as indexC7iSD70P4GMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/sign-up/index.vue?macro=true";
import { default as _91appName_938l5yTh6QBsMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/store/apps/[appName].vue?macro=true";
import { default as indexmSlXtOl53qMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/store/index.vue?macro=true";
import { default as indexmSMF3ElpZRMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue?macro=true";
import { default as indexwadB8qS5FBMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/index.vue?macro=true";
import { default as indexomj3j0RxrnMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/loading/index.vue?macro=true";
import { default as indexFoP213MJzOMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/login/index.vue?macro=true";
import { default as indextafwgkkiAhMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue?macro=true";
import { default as index9LtqCI1vWUMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue?macro=true";
import { default as new3RKFlwRmcrMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue?macro=true";
import { default as account_45configurationxxZEpjAYe4Meta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue?macro=true";
import { default as indexLBvMUFwW4OMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/m/index.vue?macro=true";
import { default as mLlJ9DWNH3qMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/m.vue?macro=true";
import { default as indexuAl885GYNaMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue?macro=true";
import { default as indexsy4LFBd4pKMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue?macro=true";
import { default as indexqoZnMtm2JLMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/p/index.vue?macro=true";
import { default as reset_45password7HuUztgJ8pMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue?macro=true";
import { default as indexIrZKFPlEALMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue?macro=true";
import { default as indexDnH5vBfxJDMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue?macro=true";
import { default as historyHDgLZRGiFyMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue?macro=true";
import { default as indexNLETuHjuc4Meta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue?macro=true";
import { default as indexWIKv5PAg0fMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue?macro=true";
import { default as importcKZ2HFHiA2Meta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue?macro=true";
import { default as indextS86rxqfEzMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue?macro=true";
import { default as schemau80oAvPiA8Meta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue?macro=true";
import { default as indexQO6vp9K3UiMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue?macro=true";
import { default as indexzvoQdriiUfMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue?macro=true";
import { default as _91id_935LPNVh6pi9Meta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue?macro=true";
import { default as index3uzx5yqXcSMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue?macro=true";
import { default as indexo1oOofKJSLMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue?macro=true";
import { default as indexlfqac4dXQZMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue?macro=true";
import { default as indexqkgfeZT4voMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue?macro=true";
import { default as index85MOHmtGXvMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue?macro=true";
import { default as _91id_93NQegjCgb2jMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[id].vue?macro=true";
import { default as index74xA3nW1OVMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue?macro=true";
import { default as integrationsAZ6xkjagrwMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue?macro=true";
import { default as indexiijTyqPQncMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue?macro=true";
import { default as indexZEORNyesSlMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue?macro=true";
import { default as index2xySYHKpGFMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue?macro=true";
import { default as _91id_93Jawwct5cecMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue?macro=true";
import { default as indexFGIsbYLfynMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue?macro=true";
import { default as newciiE0BvDeKMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue?macro=true";
import { default as user_45groupsb37IgiI7V5Meta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue?macro=true";
import { default as indexNgcwXysXUWMeta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue?macro=true";
import { default as settingsCaaQjLNON5Meta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue?macro=true";
import { default as studioYgF4RsBMZ7Meta } from "/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio.vue?macro=true";
export default [
  {
    name: "index___en",
    path: "/en",
    meta: indexxUat02VlFkMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/",
    meta: indexxUat02VlFkMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/login/index.vue")
  },
  {
    name: "login___pt",
    path: "/login",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/login/index.vue")
  },
  {
    name: "magic-link___en",
    path: "/en/magic-link",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/magic-link/index.vue")
  },
  {
    name: "magic-link___pt",
    path: "/magic-link",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/magic-link/index.vue")
  },
  {
    name: "not-yet___en",
    path: "/en/not-yet",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/not-yet.vue")
  },
  {
    name: "not-yet___pt",
    path: "/not-yet",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/not-yet.vue")
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/reset-password/index.vue")
  },
  {
    name: "reset-password___pt",
    path: "/reset-password",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/reset-password/index.vue")
  },
  {
    name: "set-workspace___en",
    path: "/en/set-workspace",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/set-workspace.vue")
  },
  {
    name: "set-workspace___pt",
    path: "/set-workspace",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/set-workspace.vue")
  },
  {
    name: "sign-up___en",
    path: "/en/sign-up",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/sign-up/index.vue")
  },
  {
    name: "sign-up___pt",
    path: "/sign-up",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/sign-up/index.vue")
  },
  {
    name: "store-apps-appName___en",
    path: "/en/store/apps/:appName()",
    meta: _91appName_938l5yTh6QBsMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/store/apps/[appName].vue")
  },
  {
    name: "store-apps-appName___pt",
    path: "/store/apps/:appName()",
    meta: _91appName_938l5yTh6QBsMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/store/apps/[appName].vue")
  },
  {
    name: "store___en",
    path: "/en/store",
    meta: indexmSlXtOl53qMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/store/index.vue")
  },
  {
    name: "store___pt",
    path: "/store",
    meta: indexmSlXtOl53qMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/store/index.vue")
  },
  {
    name: "t-tenantSlug-email-validation___en",
    path: "/en/t/:tenantSlug()/email-validation",
    meta: indexmSMF3ElpZRMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue")
  },
  {
    name: "t-tenantSlug-email-validation___pt",
    path: "/t/:tenantSlug()/email-validation",
    meta: indexmSMF3ElpZRMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue")
  },
  {
    name: "t-tenantSlug___en",
    path: "/en/t/:tenantSlug()",
    meta: indexwadB8qS5FBMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/index.vue")
  },
  {
    name: "t-tenantSlug___pt",
    path: "/t/:tenantSlug()",
    meta: indexwadB8qS5FBMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/index.vue")
  },
  {
    name: "t-tenantSlug-loading___en",
    path: "/en/t/:tenantSlug()/loading",
    meta: indexomj3j0RxrnMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/loading/index.vue")
  },
  {
    name: "t-tenantSlug-loading___pt",
    path: "/t/:tenantSlug()/loading",
    meta: indexomj3j0RxrnMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/loading/index.vue")
  },
  {
    name: "t-tenantSlug-login___en",
    path: "/en/t/:tenantSlug()/login",
    meta: indexFoP213MJzOMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/login/index.vue")
  },
  {
    name: "t-tenantSlug-login___pt",
    path: "/t/:tenantSlug()/login",
    meta: indexFoP213MJzOMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/login/index.vue")
  },
  {
    name: mLlJ9DWNH3qMeta?.name,
    path: "/en/t/:tenantSlug()/m",
    meta: mLlJ9DWNH3qMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/m.vue"),
    children: [
  {
    name: "t-tenantSlug-m-viewId___en",
    path: ":viewId()",
    meta: indextafwgkkiAhMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-recordId___en",
    path: ":viewId()/r/:recordId()",
    meta: index9LtqCI1vWUMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-new___en",
    path: ":viewId()/r/new",
    meta: new3RKFlwRmcrMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue")
  },
  {
    name: "t-tenantSlug-m-account-configuration___en",
    path: "account-configuration",
    meta: account_45configurationxxZEpjAYe4Meta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue")
  },
  {
    name: "t-tenantSlug-m___en",
    path: "",
    meta: indexLBvMUFwW4OMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/m/index.vue")
  }
]
  },
  {
    name: mLlJ9DWNH3qMeta?.name,
    path: "/t/:tenantSlug()/m",
    meta: mLlJ9DWNH3qMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/m.vue"),
    children: [
  {
    name: "t-tenantSlug-m-viewId___pt",
    path: ":viewId()",
    meta: indextafwgkkiAhMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-recordId___pt",
    path: ":viewId()/r/:recordId()",
    meta: index9LtqCI1vWUMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-new___pt",
    path: ":viewId()/r/new",
    meta: new3RKFlwRmcrMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue")
  },
  {
    name: "t-tenantSlug-m-account-configuration___pt",
    path: "account-configuration",
    meta: account_45configurationxxZEpjAYe4Meta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue")
  },
  {
    name: "t-tenantSlug-m___pt",
    path: "",
    meta: indexLBvMUFwW4OMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/m/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-p-viewId___en",
    path: "/en/t/:tenantSlug()/p/:viewId()",
    meta: indexuAl885GYNaMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId___pt",
    path: "/t/:tenantSlug()/p/:viewId()",
    meta: indexuAl885GYNaMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId-r-recordId___en",
    path: "/en/t/:tenantSlug()/p/:viewId()/r/:recordId()",
    meta: indexsy4LFBd4pKMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId-r-recordId___pt",
    path: "/t/:tenantSlug()/p/:viewId()/r/:recordId()",
    meta: indexsy4LFBd4pKMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-p___en",
    path: "/en/t/:tenantSlug()/p",
    meta: indexqoZnMtm2JLMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/p/index.vue")
  },
  {
    name: "t-tenantSlug-p___pt",
    path: "/t/:tenantSlug()/p",
    meta: indexqoZnMtm2JLMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/p/index.vue")
  },
  {
    name: "t-tenantSlug-reset-password___en",
    path: "/en/t/:tenantSlug()/reset-password",
    meta: reset_45password7HuUztgJ8pMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue")
  },
  {
    name: "t-tenantSlug-reset-password___pt",
    path: "/t/:tenantSlug()/reset-password",
    meta: reset_45password7HuUztgJ8pMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue")
  },
  {
    name: "t-tenantSlug-sign-up___en",
    path: "/en/t/:tenantSlug()/sign-up",
    meta: indexIrZKFPlEALMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue")
  },
  {
    name: "t-tenantSlug-sign-up___pt",
    path: "/t/:tenantSlug()/sign-up",
    meta: indexIrZKFPlEALMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue")
  },
  {
    name: "t-tenantSlug-sso-saml___en",
    path: "/en/t/:tenantSlug()/sso/saml",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue")
  },
  {
    name: "t-tenantSlug-sso-saml___pt",
    path: "/t/:tenantSlug()/sso/saml",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue")
  },
  {
    name: studioYgF4RsBMZ7Meta?.name,
    path: "/en/t/:tenantSlug()/studio",
    meta: studioYgF4RsBMZ7Meta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-automations-id-history___en",
    path: "automations/:id()/history",
    meta: historyHDgLZRGiFyMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue")
  },
  {
    name: "t-tenantSlug-studio-automations-id___en",
    path: "automations/:id()",
    meta: indexNLETuHjuc4Meta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-automations___en",
    path: "automations",
    meta: indexWIKv5PAg0fMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-import___en",
    path: "data/:id()/import",
    meta: importcKZ2HFHiA2Meta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id___en",
    path: "data/:id()",
    meta: indextS86rxqfEzMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-schema___en",
    path: "data/:id()/schema",
    meta: schemau80oAvPiA8Meta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue")
  },
  {
    name: "t-tenantSlug-studio-data___en",
    path: "data",
    meta: indexQO6vp9K3UiMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue")
  },
  {
    name: "t-tenantSlug-studio___en",
    path: "",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue")
  },
  {
    name: "t-tenantSlug-studio-pages-id___en",
    path: "pages/:id()",
    meta: _91id_935LPNVh6pi9Meta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-pages___en",
    path: "pages",
    meta: index3uzx5yqXcSMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue")
  },
  {
    name: settingsCaaQjLNON5Meta?.name,
    path: "settings",
    meta: settingsCaaQjLNON5Meta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-app-builder___en",
    path: "app-builder",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-authentication___en",
    path: "authentication",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-global-parameters-manager___en",
    path: "global-parameters-manager",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings___en",
    path: "",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue")
  },
  {
    name: integrationsAZ6xkjagrwMeta?.name,
    path: "integrations",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-integrations-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-integrations___en",
    path: "",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-internal___en",
    path: "internal",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-system-entities___en",
    path: "system-entities",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-tokens___en",
    path: "tokens",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue")
  },
  {
    name: user_45groupsb37IgiI7V5Meta?.name,
    path: "user-groups",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-user-groups-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups___en",
    path: "",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups-new___en",
    path: "new",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-users___en",
    path: "users",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue")
  }
]
  }
]
  },
  {
    name: studioYgF4RsBMZ7Meta?.name,
    path: "/t/:tenantSlug()/studio",
    meta: studioYgF4RsBMZ7Meta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-automations-id-history___pt",
    path: "automations/:id()/history",
    meta: historyHDgLZRGiFyMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue")
  },
  {
    name: "t-tenantSlug-studio-automations-id___pt",
    path: "automations/:id()",
    meta: indexNLETuHjuc4Meta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-automations___pt",
    path: "automations",
    meta: indexWIKv5PAg0fMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-import___pt",
    path: "data/:id()/import",
    meta: importcKZ2HFHiA2Meta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id___pt",
    path: "data/:id()",
    meta: indextS86rxqfEzMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-schema___pt",
    path: "data/:id()/schema",
    meta: schemau80oAvPiA8Meta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue")
  },
  {
    name: "t-tenantSlug-studio-data___pt",
    path: "data",
    meta: indexQO6vp9K3UiMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue")
  },
  {
    name: "t-tenantSlug-studio___pt",
    path: "",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue")
  },
  {
    name: "t-tenantSlug-studio-pages-id___pt",
    path: "pages/:id()",
    meta: _91id_935LPNVh6pi9Meta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-pages___pt",
    path: "pages",
    meta: index3uzx5yqXcSMeta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue")
  },
  {
    name: settingsCaaQjLNON5Meta?.name,
    path: "settings",
    meta: settingsCaaQjLNON5Meta || {},
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-app-builder___pt",
    path: "app-builder",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-authentication___pt",
    path: "authentication",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-global-parameters-manager___pt",
    path: "global-parameters-manager",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings___pt",
    path: "",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue")
  },
  {
    name: integrationsAZ6xkjagrwMeta?.name,
    path: "integrations",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-integrations-id___pt",
    path: ":id()",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-integrations___pt",
    path: "",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-internal___pt",
    path: "internal",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-system-entities___pt",
    path: "system-entities",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-tokens___pt",
    path: "tokens",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue")
  },
  {
    name: user_45groupsb37IgiI7V5Meta?.name,
    path: "user-groups",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-user-groups-id___pt",
    path: ":id()",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups___pt",
    path: "",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups-new___pt",
    path: "new",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-users___pt",
    path: "users",
    component: () => import("/codebuild/output/src1730893769/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue")
  }
]
  }
]
  }
]